<template>
  <div class="lpnode-style">
    <div class="outbox center-center">
      <el-card class="box-card">
        <div class="box1">
          <div class="t1">{{$t('text.t141')}}</div>
          <div class="t2">{{info.pledge_omt_node==2?$t('text.t315'):$t('text.t142')}}</div>
        </div>
        <div class="box2">
          <div class="t1 between-center">
            <div>{{$t('text.t324')}}</div>
            <div>{{info.total}}</div>
          </div>
          <div class="t1 between-center">
            <div>{{$t('text.t325')}}</div>
            <div>{{info.node_expect_release}}</div>
          </div>
          <div class="t2 between-center">
            <div>{{$t('text.t317')}}</div>
            <div>{{info.node_achievement}}</div>
          </div>
          <div class="t2 between-center">
            <div>{{$t('text.t318')}}</div>
            <div>{{info.node_level}}</div>
          </div>
        </div>
      </el-card>
    </div>
    <div class="outbox center-center">
      <el-card class="box-card">
        <div class="box2">
          <div class="t3 between-center">
            <div>{{$t('text.t319')}}</div>
            <div>{{info.pledge_omt_num}}</div>
          </div>
          <div class="t3 between-center">
            <div>{{$t('text.t320')}}</div>
            <div>{{info.pledge_omt_num_team}}</div>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>
<script>
import {mapState} from 'vuex'
export default {
  components: {
  },
  data() {
    return {
      info:{}
    };
  },
  created(){
    this.getInfo()
  },
  computed: {
    ...mapState(["config"]),
  },
  mounted() {},
  methods: {
    getInfo(){
      const user_address = this.$local.get('shop:account');
      this.$http.post('/omt_pledge/myPledgeInfo',{
        address:user_address
      }).then(res=>{
        this.info = res.data
      })
    }
  },
};
</script>

<style lang="less">
.lpnode-style {
  .outbox {
    padding: 15px 0 20px;
    color: #323232;
    .box-card {
      width: 350px;
      border-radius: 20px;
    }
    .box1{
      padding-bottom: 20px;
      border-bottom: 1px solid #E1E1E1;
      .t1{
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .t2{
        font-size: 18px;
        font-weight: 600;
      }
    }
    .box2{
      padding: 15px 0 0;
      .t1{
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 16px;
      }
      .t2{
        font-size: 12px;
        font-weight: 500;
        margin-bottom: 15px;
      }
      .t3{
        font-size: 15px;
        font-weight: 600;
        margin-bottom: 20px;
      }
    }
    .box3{
      font-size: 12px;
      font-weight: 500;
      padding: 0 40px;
    }
  }
}
</style>